import React, { useContext } from 'react';
import usePermissions from 'store/authentication/usePermissions';
import Header from 'components/Creator/Profile/Header/Header';
import ProductionsSearch from 'components/Creator/Productions/ProductionsSearch';
import ContactHistory from 'components/Creator/ContactHistory/ContactHistory';
import ProjectHistoryContainer from 'components/Creator/ProjectHistory/ProjectHistory';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Loader from 'components/UI/Loader/Loader';
import ApplicationContext from 'components/Application/Context';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import { DrawerTabsLabels } from 'components/Creator/types';
import { Property } from 'components/Property/types';
import PropertyQuery from 'components/Creator/graphql/Property.graphql';
import NavBar from 'components/Creator/NavBar/NavBar';
import { Campaign } from 'components/Campaign/types';
import './Creator.scss';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

export default function Creator({
  property: parentProperty,
  tab,
  campaign,
  userId,
  initialDrawerTab = 'socialChannels' as DrawerTabsLabels,
}: Readonly<{
  property?: Property | null;
  tab?: string;
  campaign?: Campaign;
  userId?: number;
  initialDrawerTab?: DrawerTabsLabels;
}>) {
  const [toggledTab, setToggledTab] = React.useState(initialDrawerTab);
  const { segment } = useContext(ApplicationContext);
  const propertiesPermissions = usePermissions('properties');
  const personsPermissions = usePermissions('persons');
  const apolloClient = useApolloClient();

  const handleChangeTab = React.useCallback(
    (drawerTab: DrawerTabsLabels, property: Property) => {
      setToggledTab(drawerTab);

      if (toggledTab !== drawerTab) {
        segment.track(
          SegmentEvents.CreatorProfile,
          {
            action: SegmentActions.DrawerTabChange,
            tab,
            drawer_tab: drawerTab,
            property: {
              id: property?.id,
              name: property?.name,
            },
          },
          {
            context: { groupId: formatCampaignId(campaign?.id) },
            userId: formatUserId(userId),
          },
        );
      }
    },
    [toggledTab, segment, tab, campaign?.id, userId],
  );

  const {
    data: propertyData,
    loading: propertyLoading,
    refetch: refetchProperty,
  } = useQuery(PropertyQuery, {
    variables: {
      id: parentProperty.id,
      propertiesSeeSensitiveData: Boolean(
        propertiesPermissions?.seeSensitiveData,
      ),
      personsSeeSensitiveData: Boolean(personsPermissions?.seeSensitiveData),
    },
  });

  if (propertyLoading) {
    return <Loader />;
  }

  const { property } = propertyData ?? {};

  return (
    <div className="creator-profile" data-cy="creator-profile">
      {!propertyData ? (
        'We could not load the requested data.'
      ) : (
        <>
          <Header
            property={property}
            refetchProperty={refetchProperty}
            campaign={campaign}
            canEdit
          />

          <NavBar
            toggledTab={toggledTab}
            property={property}
            onClick={handleChangeTab}
          />

          {toggledTab === 'socialChannels' ? (
            <ProductionsSearch
              productions={property?.productions}
              property={property}
              tab={tab}
              campaign={campaign}
              userId={userId}
              refetchProperty={refetchProperty}
              canAddNewChannel
              showRecentMedia
              apolloClient={apolloClient as ApolloClient<NormalizedCacheObject>}
            />
          ) : null}

          {toggledTab === 'contactHistory' ? (
            <ContactHistory property={property} />
          ) : null}

          {toggledTab === 'projectHistory' ? (
            <ProjectHistoryContainer property={property} />
          ) : null}
        </>
      )}
    </div>
  );
}
