export enum SegmentEvents {
  AddRecommendation = 'Add Recommendation',
  CreatorProfile = 'Creator Profile',
  GetMoreRecommendations = 'Get More Recommendations',
  TTCMIntegration = 'TTCM Integration',
  PerformanceReporting = 'Performance Reporting',
  OverRecommendations = 'Over Recommendation',
  PageLoad = 'Page Navigation Load',
  PageNavigation = 'Page Navigation Click',
  Recommendations = 'Recommendations',
  UserActions = 'User actions',
  BENlabsExtension = 'BENlabs Extension',
  CampaignSetup = 'Campaign Setup',
  FilterListClick = 'Filter List Click',
  InstagramStorybook = 'Instagram Storybook',
}

export enum SegmentActions {
  CardCollapse = 'Collapsed card',
  CardInputChnage = 'Chanded expected values in card',
  CardOpenedChannel = 'Opened creator channel through card',
  CardOpenedProduction = 'Opened production edit through card',
  CardSectionRating = 'Rate section in card',
  CardTabChange = 'Changed tab in card',
  CopyToClipboard = 'Copied to clipboard',
  DrawerTabChange = 'Changed drawer tab',
  DrawerTabRating = 'Rated drawer tab',
  MissingDemographics = 'No demographics data found',
  OfferFeeInput = 'Offer fee input',
  OpenCreatorPlatform = 'Open Creator Platform',
  OpenDrawer = 'Open Drawer',
  OpenModal = 'Opened modal',
  OpenProductionsSearchPage = 'Open Original Productions Page',
  OpenPropertyForm = 'Open property form',
  ReasonToBelieveInput = 'Reason to believe input',
  SimilarSearchType = 'Similar search type',
  OpenBENlabsExtension = 'Open BENlabs Extension',
  EditProduction = 'Edit Production',
  ViewProduction = 'View Production',
  FilterProductionMetrics = 'Filter production metrics',
  ViewPlacement = 'View Placement',
  FilterPlacementMetrics = 'Filter placement metrics',
  OpenDeprecatedInstagramStorybook = 'Open Deprecated Instagram Storybook',
}

export enum SegmentActionPhases {
  Load = 'Load',
  Unlock = 'Unlock',
  Submit = 'Submit',
  Reset = 'Reset',
  Cancel = 'Cancel',
}
