import gql from 'graphql-tag';

export default gql`
  query LoggedUser($userId: Int!) {
    user(id: $userId) {
      id
      team
      client {
        id
        name
        appRouting
      }
      createdBy {
        id
      }
      createdAt
      updatedBy {
        id
      }
      updatedAt
      role
      email
      firstName
      lastName
      title
      company
      territory
      country
      status
      role
      team
      lastAccess
      flags
      metricsDisplay
      phoneNumber
      userPresets
      featureFlags
      extraPermissions
    }
  }
`;
