import React from 'react';
import 'ag-grid-enterprise';
import classnames from 'classnames';
import { AgGridReact } from 'ag-grid-react';
import UILoader from 'components/UI/Loader/Loader';
import {
  IServerSideDatasource,
  ColDef,
  ColumnApi,
  ColumnState,
  SideBarDef,
  FilterChangedEvent,
  SelectionChangedEvent,
  RowClassRules,
  ServerSideStoreType,
  ToolPanelDef,
  GridReadyEvent,
} from 'ag-grid-community';
import useFullFeatureFlags from 'store/authentication/useFullFeatureFlags';
import useDebounce from 'utils/useDebounce';
import getGridOptions from './utils/gridOptions';
import processCellFromClipboard from './utils/processCellFromCLipboard';
import getRenderingSpecificProps from './utils/getRenderingSpecificProps';
import RowsCountStatusBar from './components/RowsCountStatusBar';
import { saveColumnState } from './utils/savedState';
import baseDefaultColDef from './utils/defaultColDef';

/* eslint-disable-next-line */
export default function InnerGrid({
  className,
  enableCellChangeFlash: parentEnableCellChangeFlash,
  enableCharts: parentEnableCharts,
  enableFillHandle: parentEnableFillHandle,
  enableRangeSelection: parentEnableRangeSelection,
  sideBar: parentSideBar,
  toolPanels: parentToolPanels,
  undoRedoCellEditing: parentUndoRedoCellEditing,
  undoRedoCellEditingLimit: parentUndoRedoCellEditingLimit,
  columnStateKey,
  columnDefs,
  frameworkComponents,
  defaultColDef,
  rowModelType,
  context,
  rowHeight,
  rowClassRules,
  rowSelection,
  loadingOverlayComponent,
  onGridReady,
  rowData,
  onSelectionChanged,
  onFilterChanged,
  setColumnState,
  singleClickEdit,
  components = {},
  infinityGrid,
  gridApi,
  setGridApi,
  datasource,
  dataCy,
  suppressScrollOnNewData = true,
  suppressRowClickSelection = true,
  campaignSubType,
  useDestinationGrid = false,
  stopEditingWhenCellsLoseFocus = false,
  onRowDataUpdated,
}: Readonly<{
  className?: string;
  enableCellChangeFlash?: boolean;
  enableCharts?: boolean;
  enableFillHandle?: boolean;
  enableRangeSelection?: boolean;
  sideBar?: string | boolean | SideBarDef | string[];
  toolPanels?: (ToolPanelDef | string)[] | null;
  undoRedoCellEditing?: boolean;
  undoRedoCellEditingLimit?: number;
  columnStateKey?: string | null;
  columnDefs?: ColDef[];
  frameworkComponents?: Record<string, unknown>;
  defaultColDef?: ColDef;
  rowModelType?: string;
  serverSideStoreType?: ServerSideStoreType;
  context: Record<string, unknown>;
  rowHeight: number;
  rowClassRules: RowClassRules;
  rowSelection?: 'single' | 'multiple';
  loadingOverlayComponent: string;
  onGridReady: (arg0: GridReadyEvent) => void;
  rowData?: Record<string, unknown>[];
  blockLoadDebounceMillis?: number;
  rowBuffer?: number;
  cacheBlockSize?: number;
  maxBlocksInCache?: number;
  onRowDataUpdated?: (arg0: unknown) => void;
  onSelectionChanged?: (arg0: SelectionChangedEvent) => void;
  onFilterChanged?: (arg0: FilterChangedEvent) => void;
  setColumnState: (arg0: ColumnState) => void;
  singleClickEdit?: boolean;
  columnState?: ColumnState | null;
  components?: Record<string, unknown>;
  infinityGrid?: boolean;
  gridApi?: GridReadyEvent;
  setGridApi?: (params: GridReadyEvent) => void;
  datasource?: IServerSideDatasource | null;
  dataCy?: string;
  suppressScrollOnNewData: boolean;
  suppressRowClickSelection: boolean;
  campaignSubType: string;
  useDestinationGrid?: boolean;
  stopEditingWhenCellsLoseFocus?: boolean;
}>) {
  const [rowsCount, setRowsCount] = React.useState(0);
  const [rowsTotalResult, setRowsTotalResult] = React.useState(0);
  const featureFlags = useFullFeatureFlags();
  const {
    enableFillHandle,
    enableRangeSelection,
    sideBar,
    statusBar,
  } = getGridOptions({
    enableCellChangeFlash: Boolean(parentEnableCellChangeFlash),
    enableCharts: Boolean(parentEnableCharts),
    enableFillHandle: Boolean(parentEnableFillHandle),
    enableRangeSelection: Boolean(parentEnableRangeSelection),
    featureFlags,
    sideBar: parentSideBar,
    toolPanels: parentToolPanels,
    undoRedoCellEditing: Boolean(parentUndoRedoCellEditing),
    undoRedoCellEditingLimit: Number(parentUndoRedoCellEditingLimit),
    rowModelType,
  });

  const onDisplayedColumnsChanged = useDebounce(
    (ev: { columnApi: ColumnApi }) => {
      if (!columnStateKey) {
        return;
      }

      const newColumnState = saveColumnState(
        ev.columnApi,
        columnStateKey,
        Boolean(infinityGrid),
        campaignSubType,
      );
      setColumnState(newColumnState as ColumnState);
    },
    500,
  );

  const renderingSpecificProps = React.useMemo(
    () =>
      getRenderingSpecificProps(
        columnDefs,
        columnStateKey,
        datasource,
        gridApi,
        Boolean(infinityGrid),
        onGridReady,
        onSelectionChanged,
        rowData,
        rowsCount,
        rowsTotalResult,
        setGridApi,
        setRowsCount,
        setRowsTotalResult,
        campaignSubType,
      ),
    [
      columnDefs,
      columnStateKey,
      datasource,
      gridApi,
      infinityGrid,
      onGridReady,
      onSelectionChanged,
      rowData,
      rowsCount,
      rowsTotalResult,
      setGridApi,
      campaignSubType,
    ],
  );

  return (
    <div
      data-cy={dataCy}
      className={classnames(
        `ag-theme-${useDestinationGrid ? 'destination' : 'alpine'}`,
        className,
      )}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {/* @ts-ignore */}
      <AgGridReact
        {...(renderingSpecificProps ?? {})}
        onFilterChanged={onFilterChanged}
        processCellFromClipboard={processCellFromClipboard}
        sideBar={sideBar}
        statusBar={statusBar}
        suppressScrollOnNewData={suppressScrollOnNewData}
        suppressRowClickSelection={suppressRowClickSelection}
        singleClickEdit={singleClickEdit}
        enableFillHandle={enableFillHandle}
        enableRangeSelection={enableRangeSelection}
        onColumnMoved={onDisplayedColumnsChanged}
        onColumnPinned={onDisplayedColumnsChanged}
        onColumnVisible={onDisplayedColumnsChanged}
        components={{
          ...(components ?? {}),
          ...(frameworkComponents ?? {}),
          customLoadingOverlay: UILoader,
          [RowsCountStatusBar.componentName]: RowsCountStatusBar,
        }}
        defaultColDef={{
          ...(baseDefaultColDef ?? {}),
          ...(defaultColDef ?? {}),
        }}
        context={context}
        rowHeight={rowHeight}
        rowClassRules={rowClassRules}
        rowSelection={rowSelection}
        loadingOverlayComponent={loadingOverlayComponent}
        stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus}
        onRowDataUpdated={onRowDataUpdated}
      />
    </div>
  );
}
