import React from 'react';
import { FetchPolicy } from 'apollo-client';
import { useSubscription } from '@apollo/react-hooks';
import PlacementByCampaignSub from 'components/Campaign/Influencer/View/Stats/graphql/PlacementByCampaignSub';
import { CreatorColDef } from 'shared/types/Campaign';
import useLoadEntities from './useLoadEntities';
import PlacementSearchQuery from '../graphql/Placements.graphql';
import PlacementQuery from '../graphql/Placement.graphql';

export default function usePlacementsLoader({
  id,
  reportingWindow,
  campaignStartDate,
  columns,
  isExternal = false,
}: {
  id: number;
  reportingWindow?: string | null;
  campaignStartDate?: string;
  columns: Record<string, CreatorColDef>;
  isExternal: boolean;
}) {
  const postCustomColumnsUuid = React.useMemo(() => {
    const columnsArray = Object.entries(columns ?? {});
    return columnsArray.reduce<string[]>((acc, item) => {
      if (item?.[1]?.type === 'customManualMetric') {
        return [...acc, item[0]];
      }
      return acc;
    }, []);
  }, [columns]);

  const placementVariables = React.useMemo(() => {
    if (reportingWindow && campaignStartDate) {
      return {
        variables: {
          limit: 100,
          sort: 'lastEdit-desc',
          f: {
            campaignId: id,
            deliverableStatusPhaseExclude: ['rejected'],
          },
          reportingWindow,
          campaignStartDate,
          postCustomColumnsUuid,
          isExternal,
        },
        fetchPolicy: 'network-only' as FetchPolicy,
      };
    }
    return null;
  }, [id, reportingWindow, campaignStartDate, postCustomColumnsUuid]);

  const { gridQuery, gridQueryOptions, loading, gridApi, setGridApi, refetchSingleEntity } =
    useLoadEntities({
      entitiesName: 'placements',
      query: PlacementSearchQuery,
      singleEntityQuery: PlacementQuery,
      filter: placementVariables,
    });

  useSubscription(PlacementByCampaignSub, {
    onSubscriptionData: ({ subscriptionData: rData }) => {
      if (rData?.data?.placementByCampaign?.id) {
        if (!['update', 'create'].includes(rData?.data?.placementByCampaign?.action)) {
          return;
        }
        refetchSingleEntity({
          id: rData.data.placementByCampaign.id,
          context: rData.data.placementByCampaign,
          reportingWindow,
          campaignStartDate,
          postCustomColumnsUuid,
          isExternal,
        });
      }
    },
    variables: {
      campaignId: id,
    },
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
  });

  return {
    gridQuery,
    gridQueryOptions,
    setGridApi,
    gridApi,
    loading,
  };
}
